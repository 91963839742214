import { effect, inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { User } from '@core/models/entities/User/User';
import { UserSettings } from '@core/models/entities/User/UserSettings';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUserService {
  userSubject = new BehaviorSubject<User | null>(null);
  #userService = inject(UserService);
  userSettingsSubject = new BehaviorSubject<UserSettings | null>(null);
  userSettings$ = this.userSettingsSubject.asObservable();
  user$ = this.userSubject.asObservable();
  user = signal<User | null>(null);
  userSettings = signal<UserSettings | null>(null);
  now = signal(new Date().getTime());
  #refreshTime = 1000 * 60 * 60;
  expirationTime = signal(new Date().getTime());
  checkExpiration = signal<boolean>(false);

  constructor() {
    this.user$.subscribe((value) => {
      this.user.set(value);
      this.userSettings.set(value ? value.settings : null);
    });

    effect(
      () => {
        if (this.user()) {
          this.expirationTime.set(new Date(this.user()!.mercureSubscribeToken.expiresAt).getTime());
          this.executeFunctionAtSpecificTime(this.#userService.getUserMe(), this.expirationTime() - this.#refreshTime);
          if (this.expirationTime() - this.now() < this.#refreshTime) {
            this.#userService.getUserMe().subscribe((user) => {
              this.userSubject.next(user);
              this.userSettingsSubject.next(user.settings);
            });
          }
          this.checkExpiration.set(false);
        }
      },
      { allowSignalWrites: true },
    );
  }

  executeFunctionAtSpecificTime(callback: Observable<User>, time: number) {
    const targetTime = new Date();
    targetTime.setTime(time);
    let isExecuted = false;

    const intervalId = setInterval(() => {
      const now = new Date();
      if (!isExecuted && now >= targetTime) {
        clearInterval(intervalId);
        isExecuted = true;
        callback.subscribe((user) => {
          this.userSubject.next(user);
          this.userSettingsSubject.next(user.settings);
        });
      }
    }, this.#refreshTime); // Check every second
  }

  setApplicationUser(): void {
    this.#userService.getUserMe().subscribe((user) => {
      this.userSubject.next(user);
      this.userSettingsSubject.next(user.settings);
    });
  }
}
